import React, { useCallback, useMemo, useRef, useState } from "react";
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";
import styled from "styled-components";
import NextIcon from "../../images/next.svg";
import PrevIcon from "../../images/prev.svg";
import { getFileParts } from "../../utils/browser";

interface PictureCarouselProps {
  items: any[];
  className?: string;
  index?: number;
  onIndexChange?: (value?: number) => void;
  prevIcon?: string;
  nextIcon?: string;
  interval?: boolean | number;
  hideControls?: boolean;
  hideIndicators?: boolean;
}

const Indicators = styled(CarouselIndicators)`
  display: ${props => (props.$hideIndicators ? "none" : undefined)};

  li {
    margin: 0 15px;
    width: 10px;
    height: 10px;
    opacity: 0.4;
    border-radius: 50%;
    background-color: ${props =>
      props.$indicatorColor ? `${props.$indicatorColor} !important` : "rgba(0, 0, 0, 0.1)"};
  }

  li.active {
    background-color: #7ad4ff !important;
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const PrevControl = styled(CarouselControl)`
  opacity: 1;

  .carousel-control-prev-icon {
    background-image: url(${props => props.$controlIcon});
  }
`;

const NextControl = styled(CarouselControl)`
  opacity: 1;

  .carousel-control-next-icon {
    background-image: url(${props => props.$controlIcon});
  }
`;

interface CarouselImageProps {
  src: string | React.FC;
  useWebp?: boolean;
  altText?: string;
}

const CarouselImage = React.memo(({ src, altText, useWebp = false }: CarouselImageProps) => {
  if (typeof src !== "string") {
    const Picture = src;
    return <Picture />;
  }

  const { base, extension } = getFileParts(src);
  if (extension === "webp" || useWebp) {
    // if the image is a png
    const setExt = extension === "webp" ? "jpg" : extension;
    const srcSet = `
          ${base}@150w.${setExt} 150w,
          ${base}@300w.${setExt} 300w,
          ${base}@900w.${setExt} 900w,
          ${base}@1200w.${setExt} 1200w,
          ${base}@1500w.${setExt} 1500w,
          ${base}@1800w.${setExt} 1800w,
          ${base}@2100w.${setExt} 2100w
        `;
    const webPsrcSet = `
          ${base}@150w.webp 150w,
          ${base}@300w.webp 300w,
          ${base}@900w.webp 900w,
          ${base}@1200w.webp 1200w,
          ${base}@1500w.webp 1500w,
          ${base}@1800w.webp 1800w,
          ${base}@2100w.webp 2100w
        `;

    return (
      <picture>
        <source type="image/webp" srcSet={webPsrcSet} />
        <source type="image/jpeg" srcSet={srcSet} />
        <Image src={`${base}@900w.${setExt}`} alt={altText} />
      </picture>
    );
  }

  return <Image src={src} alt={altText} />;
});

const PictureCarousel = React.memo(
  ({
    className,
    items,
    prevIcon,
    nextIcon,
    index,
    onIndexChange,
    interval = false,
    hideControls = false,
    hideIndicators = false
  }: PictureCarouselProps) => {
    // hooks
    const animatingRef = useRef(false);
    const [uncontrolledIndex, setUncontrolledIndex] = useState(0);
    const activeIndexRef = useRef(index !== undefined ? index : 0);
    const prevControlIcon = useMemo(() => prevIcon || PrevIcon, [prevIcon]);
    const nextControlIcon = useMemo(() => nextIcon || NextIcon, [nextIcon]);

    // variables
    const isControlled = index !== undefined;
    const activeIndex = isControlled ? index : uncontrolledIndex;

    // functions
    const next = useCallback(() => {
      if (animatingRef.current) {
        return;
      }

      // update active index
      activeIndexRef.current = (activeIndexRef.current + 1) % items.length;

      // update index state
      if (isControlled) {
        onIndexChange(activeIndexRef.current);
      } else {
        setUncontrolledIndex(activeIndexRef.current);
      }
    }, [items, isControlled, onIndexChange]);

    const previous = useCallback(() => {
      if (animatingRef.current) {
        return;
      }

      // update active index
      activeIndexRef.current = (activeIndexRef.current - 1 + items.length) % items.length;

      // update index state
      if (isControlled) {
        onIndexChange(activeIndexRef.current);
      } else {
        setUncontrolledIndex(activeIndexRef.current);
      }
    }, [items, isControlled, onIndexChange]);

    const goToIndex = useCallback(
      newIndex => {
        if (animatingRef.current || hideIndicators) {
          return;
        }

        // update active index
        if (isControlled) {
          onIndexChange(newIndex);
        } else {
          setUncontrolledIndex(newIndex);
        }
      },
      [isControlled, onIndexChange, hideIndicators]
    );

    const onExiting = useCallback(() => (animatingRef.current = true), []);
    const onExited = useCallback(() => (animatingRef.current = false), []);

    return (
      <Carousel
        interval={interval}
        className={className}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <Indicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          $hideIndicators={hideIndicators}
          $indicatorColor={!hideIndicators && items[activeIndex].indicatorColor}
        />
        {items.map(item => (
          <CarouselItem key={item.key} onExiting={onExiting} onExited={onExited}>
            <CarouselImage {...item} />
          </CarouselItem>
        ))}
        {hideControls ? null : (
          <>
            <PrevControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
              $controlIcon={prevControlIcon}
            />
            <NextControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
              $controlIcon={nextControlIcon}
            />
          </>
        )}
      </Carousel>
    );
  }
);

export default PictureCarousel;
