import { useCallback, useContext } from "react";
import ProductConstants from "../constants/Products";
import { CartContext, CartProviderProps } from "../contexts/CartContext";
import { addToCart, getSubTotal, updateCart } from "../utils/shopify";
import Tracking from "../utils/tracking";

interface LineItem extends ShopifyBuy.LineItem {
  variant?: any;
}

const useBuyNow = () => {
  // hooks
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { dispatch } = cartContext;
  const {
    cart,
    metadata: { skuVariantMap, lineItems }
  } = cartContext.state;

  return useCallback(
    (sku: string) => {
      const quantity = (cart[sku] || 0) + 1;
      const variantId = skuVariantMap[sku];
      const lineItemId = lineItems[sku];

      // create a brand new line item
      if (!lineItemId) {
        addToCart([{ quantity: 1, variantId }]).then(checkout => {
          // find line item id
          const lineItem = checkout.lineItems.find(
            (lineItem: LineItem) => lineItem.variant.id === variantId
          );

          if (!!lineItem) {
            dispatch({
              sku,
              lineItem: lineItem.id,
              subTotal: getSubTotal(checkout),
              type: "addToCart"
            });
          }
        });
      } else {
        updateCart([{ id: lineItemId, quantity }]).then(checkout => {
          dispatch({
            sku,
            lineItem: lineItemId,
            subTotal: getSubTotal(checkout),
            type: "addToCart"
          });
        });
      }

      // track add to cart
      const base_info = { currency: "USD", value: ProductConstants.Products[sku].price };
      Tracking.fb("AddToCart", base_info);
      Tracking.pintrk("addtocart", { ...base_info, order_quantity: 1 });
    },
    [cart, skuVariantMap, lineItems, dispatch]
  );
};

export default useBuyNow;
